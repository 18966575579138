(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("sbJsExtends"), require("mobxReact"), require("mobxUtils"), require("sbRespBlockLib"), require("gsap"), require("sbMsgBusModule"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-pulse-bet-blocks", ["React", "mobx", "sbJsExtends", "mobxReact", "mobxUtils", "sbRespBlockLib", "gsap", "sbMsgBusModule", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-pulse-bet-blocks"] = factory(require("React"), require("mobx"), require("sbJsExtends"), require("mobxReact"), require("mobxUtils"), require("sbRespBlockLib"), require("gsap"), require("sbMsgBusModule"), require("ReactDOM"));
	else
		root["sb-responsive-pulse-bet-blocks"] = factory(root["React"], root["mobx"], root["sbJsExtends"], root["mobxReact"], root["mobxUtils"], root["sbRespBlockLib"], root["gsap"], root["sbMsgBusModule"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__23__) {
return 